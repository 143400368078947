import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import bgVideo from './assets/backgroundvideo.mp4';
import { createTheme, responsiveFontSizes,   ThemeProvider, Typography } from '@material-ui/core';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const myHeading = "Education Strategy Consulting";
const myText = "Enquiries: mk@secondbench.com";

const title1 = {
  color: '#00ffff'
}
const title2 = {
  color: '#1ecbe1'
}
const title3 = {
  color: '#60B7F3'
}
const title4 = {
  color: '#66CDAA'
}
const title5 = {
  color: '#AFEEEE'
}

const contact = {
  color: '#FF69B4'
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /*
  <React.StrictMode>
    <App />
  </React.StrictMode>
  */

<>

<video id='my_video'
    style={{
      position:"absolute",
      width:"100%",
      left:"50%",
      top:"50%",
      height:"100%",
      objectFit:"cover",
      transform:"translate(-50%, -50%",
      zIndex:"-1",
      opacity:"100%"
    }}
    src={bgVideo} autoPlay loop muted />
<div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
    <ThemeProvider theme={theme}>
      <Typography variant="h3" gutterBottom ><span style={title1}> {myHeading}</span>
        <br />
        <span style={title3}>Custom Generative AI Soulutions </span>
        <br />
        <span style={title4}>Data Mining</span>
        <br />
        <span style={title5}>Systems Analysis & Design</span>
      </Typography>
      
      <Typography variant="subtitle1" gutterBottom>
          <span style={contact}>{myText}</span>
      </Typography>
      
    </ThemeProvider>
</div>
</>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
